import { PDF_PATHS } from "~/constants/pdf-paths";

type Keys = keyof typeof PDF_PATHS;
type Locales = keyof typeof PDF_PATHS[Keys];

const FALLBACK_LOCALE = 'en';

export default function useLocalePdfPath() {
  const { $i18n: { locale } } = useNuxtApp();
  
  return (key: Keys) => PDF_PATHS[key]?.[locale.value as Locales] ?? PDF_PATHS[key]?.[FALLBACK_LOCALE];
}
